import {
    EditableComponentInfo,
    ProjectInfo,
    ComponentSelectEvent,
    ArbitraryFunction
} from "../InternalModules";

export class HotSpotEditorApplication {

    private _rendererView: GameRendererView;

    protected _editableCompMap: Map<string, EditableComponentInfo>;

    private _focusedComponent: Component | undefined;

    protected _componentFocusedCallBacks: Array<ComponentSelectEvent>;
    protected _componentUnfocusedCallBacks: Array<ComponentSelectEvent>;

    constructor(container: HTMLElement) {
        this._rendererView = new GameRendererView(container);
        window.globalVars.rendererView = this._rendererView;
        window.globalVars.app = this;
        this._editableCompMap = new Map();

        this._componentFocusedCallBacks = new Array();
        this._componentUnfocusedCallBacks = new Array();
    }

    public get rendererView(): GameRendererView {
        return this._rendererView;
    }

    protected get focusedComponent(): Component | undefined {
        return this._focusedComponent;
    }
    protected set focusedComponent(value: Component | undefined) {
        const targetChanged = value !== this._focusedComponent;
        if (targetChanged && !!this._focusedComponent) {
            this.triggerComponentUnfocusedEvent(this._focusedComponent);
        }
        this._focusedComponent = value;
        if (targetChanged && !!this._focusedComponent) {
            this.triggerComponentFocusedEvent(this._focusedComponent);
        }
    }

    protected _start() {
        game._enabledJoystick = game._isMobile;
        game.initialize();
        $(window).trigger("resize");
    }

    public loadProject(projInfo: ProjectInfo) {

        window.globalVars.currentViewMode = projInfo.mode;
        window.globalVars.mmoOptions = projInfo.mmoOptions;
        window.globalVars.isMMOMode = !!window.globalVars.mmoOptions;
        window.globalVars.forceThirdPersonView = window.globalVars.isMMOMode;

        const loadingPromise: Promise<Map<string, EditableComponentInfo>> = new Promise((resolve, reject) => {
            this._editableCompMap.clear();
            ProjectManager.getInstance().loadOrRebuildProjectInfo(projInfo.projectFolder).then(() => {

                //如果有后缀则将后缀作为场景名
                if (projInfo.mainScene)
                    ProjectManager.getInstance().editingScenePath = projInfo.projectFolder + projInfo.mainScene;

                if (!!projInfo.sceneData) {
                    scene.import(projInfo.sceneData).then(() => {
                        resolve(this._editableCompMap);
                    });
                    (loadingPresenterInstance as any).addEventListener('onLifeChange', (e1: { value: string; }) => {
                        if (e1.value != "Start") this._start();
                    });
                } else {
                    const editingScenePath = ProjectManager.getInstance().editingScenePath;
                    const fileLoader = new THREE.FileLoader(THREE.DefaultLoadingManager);
                    fileLoader.load(editingScenePath, (content) => {
                        try {
                            let json = JSON.parse(content as string);
                            scene.import(json).then(() => {
                                resolve(this._editableCompMap);
                            });
                            (loadingPresenterInstance as any).addEventListener('onLifeChange', (e1: { value: string; }) => {
                                if (e1.value != "Start") this._start();
                            });


                        } catch (error) {
                            console.error(editingScenePath, error);
                        }
                    });
                }
            }).catch((err: ErrorEvent) => {
                console.log(`load project info error: ${err}`);
                reject(err);
            });
        });

        return loadingPromise;
    }

    public registerEditableComponent(eitableComp: EditableComponentInfo) {
        this._editableCompMap.set(eitableComp.uuid, eitableComp);
    }

    public isComponentEditable(compUUID: string) {
        return this._editableCompMap.has(compUUID);
    }

    /** For UI */

    public setComponentAsset(compUUID: string, assetURL: string, assetThumbnailURL: string = "") {
        const targetComp = this._editableCompMap.get(compUUID);
        if (!!targetComp && targetComp.comp instanceof ExhibitImg) {

            if (targetComp.comp.imgSource === assetURL as unknown as number) {
                return;
            }

            targetComp.comp.imgSource = assetURL as unknown as number;

            const isVideoAsset = extensions.video.includes(AssetLibrary.GetExtFromUrl(assetURL));

            if (!!assetThumbnailURL) {
                targetComp.comp.videoThumbnail = assetThumbnailURL;
            } else if (isVideoAsset) {
                if (targetComp.comp.isPlayingVideo) {
                    targetComp.comp.stopVideo();
                }
                const projMgrInst = ProjectManager.getInstance();
                targetComp.comp.videoThumbnail = projMgrInst.getAbsUrl(projMgrInst.getThumbnailByUrl(assetURL));
            }


        } else {
            console.warn(`Component ${compUUID} is not editable !!`);
        }
    }

    public selectComponentFromList(compUUID: string) {
        if (this._editableCompMap.has(compUUID)) {
            this.triggerEditableComponentClicked(this._editableCompMap.get(compUUID)!.comp);
        } else {
            console.warn(`Component ${compUUID} is not editable !!`);
        }
    }

    public registerComponentFocusedEvent(callback: ComponentSelectEvent) {
        this._registerEvent(this._componentFocusedCallBacks, callback);
    }
    public unregisterComponentFocusedEvent(callback: ComponentSelectEvent) {
        this._unregisterEvent(this._componentFocusedCallBacks, callback);
    }
    public triggerComponentFocusedEvent(comp: Component) {
        this._triggerCallbacks(this._componentFocusedCallBacks, (comp as unknown as THREE.Mesh).uuid, comp);
    }

    public registerComponentUnfocusedEvent(callback: ComponentSelectEvent) {
        this._registerEvent(this._componentUnfocusedCallBacks, callback);
    }
    public unregisterComponentUnfocusedEvent(callback: ComponentSelectEvent) {
        this._unregisterEvent(this._componentUnfocusedCallBacks, callback);
    }
    public triggerComponentUnfocusedEvent(comp: Component) {
        this._triggerCallbacks(this._componentUnfocusedCallBacks, (comp as unknown as THREE.Mesh).uuid, comp);
    }

    public triggerEditableComponentClicked(comp: Component) {
        const cam = this.rendererView.camera as THREE.PerspectiveCamera;
        const meshComp = comp as unknown as THREE.Mesh;
        const compWorldPos = new THREE.Vector3();
        meshComp.getWorldPosition(compWorldPos);
        const viewDist = (comp as unknown as ExhibitImg).viewDistance || 1;
        let viewPos = new THREE.Vector3();
        viewPos.copy(meshComp.position);
        viewPos.z += viewDist;
        viewPos = meshComp.parent!.localToWorld(viewPos);
        viewPos = cam.parent!.parent!.worldToLocal(viewPos);

        let viewQuaternion = meshComp.getWorldQuaternion(new THREE.Quaternion());
        const camParentQuatInvert = cam.parent!.parent!.getWorldQuaternion(new THREE.Quaternion()).inverse();
        viewQuaternion.multiplyQuaternions(viewQuaternion, camParentQuatInvert);

        cam.parent!.position.copy(viewPos);
        // cam.parent!.lookAt(compWorldPos);
        cam.parent?.quaternion.set(viewQuaternion.x, viewQuaternion.y, viewQuaternion.z, viewQuaternion.w);
        cam.rotation.x = 0;

        this.focusedComponent = comp;
        console.log(`Target ${(this.focusedComponent as unknown as THREE.Mesh).uuid} locked!`);
    }

    public triggerPlayerMoved() {
        if (!!this.focusedComponent) {
            console.log(`Target ${(this.focusedComponent as unknown as THREE.Mesh).uuid} lost!`);
            this.focusedComponent = undefined;
        }

        let nearestVideoComp: ExhibitImg | undefined = undefined;
        let playingVideoComps: Array<ExhibitImg> = [];
        let nearestDist = 1e9;
        const cam = this.rendererView.camera as THREE.PerspectiveCamera;
        const frustum = new THREE.Frustum();
        const compWorldPos = new THREE.Vector3();
        const camWorldPos = new THREE.Vector3();
        frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(cam.projectionMatrix, cam.matrixWorldInverse));
        cam.getWorldPosition(camWorldPos);

        for (let i = 0; i < ExhibitImg.instances.length; i++) {
            const comp = ExhibitImg.instances[i] as ExhibitImg;

            if (!!comp.videoURL) {
                (comp as unknown as THREE.Object3D).getWorldPosition(compWorldPos);
                const distSquared = camWorldPos.distanceToSquared(compWorldPos);
                if (frustum.containsPoint(compWorldPos) &&
                    distSquared < nearestDist) {
                    nearestVideoComp = comp;
                    nearestDist = distSquared;
                }

                if (comp.isPlayingVideo) {
                    playingVideoComps.push(comp);
                }
            }
        }

        if (playingVideoComps.length > 0) {
            playingVideoComps.forEach(c => c !== nearestVideoComp && (
                !!nearestVideoComp ? c.stopVideo() : c.pauseVideo()
            ));
        }

        if (!!nearestVideoComp &&
            (!nearestVideoComp.isPlayingVideo ||
                nearestVideoComp.isVideoPaused)) {
            nearestVideoComp.playVideo();
        }
    }

    public getEditableComponentsInfo() {
        const infoList = new Array<{
            id: string,
            name: string,
            compInfo: EditableComponentInfo
        }>();
        for (const [key, value] of this._editableCompMap) {
            infoList.push({
                id: key,
                name: value.actor.obj.name,
                compInfo: value
            });
        }

        return infoList;
    }

    public exportSceneJsonData() {
        return scene.export();
    }

    protected _registerEvent(callbackList: Array<ArbitraryFunction>, callback: ArbitraryFunction) {
        if (!callbackList.includes(callback)) {
            callbackList.push(callback);
        }
    }

    protected _unregisterEvent(callbackList: Array<ArbitraryFunction>, callback: ArbitraryFunction) {
        const targetCallBackIndex = callbackList.indexOf(callback);
        if (targetCallBackIndex > -1) {
            callbackList.splice(targetCallBackIndex, 1);
        }
    }

    protected _triggerCallbacks(callbackList: Array<ArbitraryFunction>, ...args: any) {
        if (callbackList.length > 0) {
            const callbacks = callbackList.slice();
            for (let i = 0; i < callbacks.length; ++i) {
                const callback = callbacks[i];
                callback(...args);
            }
        }
    }

}