
export enum ViewMode {
    Visit = "visit",
    Edit = "edit",
}

export type GlobalVariables = {
    globalVars: GameRendererView;
};

export type MMOOption = {
    playerName: string, //与会者名称（必选）
    id?: string,         //与会者ID
    title?: string,     //与会者职位信息
    gender?: number,    //与会者性别
    avatarID?: number,   //与会者角色编号
    spaceID?: string,   //场地ID
    roomID?: string,    //房间ID

    userName?: string,   //网络认证用户名
    password?: string,   //网络认证密码

    meta?: { [key: string]: any }
}

export type ProjectInfo = {
    projectFolder: string;
    mainScene: string;
    sceneData: any;
    mode: ViewMode;
    mmoOptions: MMOOption;
}

export type EditableComponentInfo = {
    uuid: string;
    comp: Component;
    actor: Actor;
}

export type ComponentSelectEvent = (compID: string, comp: Component) => void;

export type ArbitraryFunction = (...arg: any) => any;